import React from 'react';
class BaseModelController extends React.Component {
  constructor() {
    super();
    this.onDimensionChanged = this.onDimensionChanged.bind(this);
  }
  componentDidMount() {
    const {
      editing,
      group,
      "data-file": file = "",
      loadMetadata
    } = this.props;
    debugger;
    if (editing && file != "") {
      loadMetadata(editing ? "preview" : group, file); //edit mode don't use provider load the data for preview purposes
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      editing,
      group,
      "data-file": file = "",
      loadMetadata
    } = this.props;
    if (prevProps) {
      const {
        "data-file": prevFile
      } = prevProps;
      debugger;
      if (editing && file != prevFile && file != "") {
        loadMetadata(editing ? "preview" : group, file); //edit mode don't use provider load the data for preview purposes
      }
    }
  }
  onDimensionChanged(dimension) {
    debugger;
    const {
      data,
      "data-group": group,
      onModelUpdate
    } = this.props;
    const newData = {
      ...data
    };
    const target = newData.dimensions.findIndex(d => d.code === dimension.code);
    newData.dimensions[target] = dimension;
    onModelUpdate(group, newData);
  }
}
export default BaseModelController;