import Immutable from 'immutable';
import * as storaga from './storage';
const METADATA_LOAD = "METADATA";
const MODEL_LOADED = "MODEL_LOADED";
const MODEL_UPDATED = "MODEL_UPDATED";
const MODEL_RESTORED = "MODEL_RESTORED";
const METADATA_LOAD_FAILURE = "METADATA_LOAD_FAILURE";
export const toggleDimension = (group, selection) => (dispatch, getState) => {
  const currentData = getState().getIn(['dc', group, 'data']);
  currentData.dimensions.forEach(dim => dim.code === selection ? dim.selected = !dim.selected : dim.selected);
  dispatch({
    type: MODEL_UPDATED,
    group: group,
    data: currentData
  });
};
export const onChangeAttribute = (group, path, value) => (dispatch, getState) => {
  debugger;
};
export const onModelUpdate = (group, model) => (dispatch, getState) => {
  dispatch({
    type: MODEL_UPDATED,
    group: group,
    data: model
  });
};
export const restoreState = (group, model) => (dispatch, getState) => {
  //const storedData = storaga.getStorageValue("dc-" + group, null)
  dispatch({
    type: MODEL_RESTORED,
    group,
    data: model
  });
};
export const loadMetadata = (group, file) => (dispatch, getState) => {
  dispatch({
    type: METADATA_LOAD,
    group
  });
  fetch(file).then(response => {
    response.json().then(function (data) {
      dispatch({
        type: MODEL_LOADED,
        group,
        data
      });
    }).catch(() => dispatch({
      type: METADATA_LOAD_FAILURE,
      group
    }));
  }).catch(() => dispatch({
    type: METADATA_LOAD_FAILURE,
    group
  }));
};
let initialState = Immutable.Map({});
export default ((state = initialState, action) => {
  switch (action.type) {
    case METADATA_LOAD:
      {
        const {
          group
        } = action;
        const newState = state.setIn([group, 'status'], 'loading').setIn([group, 'data'], null);
        return newState;
      }
    case MODEL_LOADED:
      {
        const {
          data,
          group
        } = action;
        debugger;
        const newState = state.setIn([group, 'status'], 'done').setIn([group, 'data'], data)
        // .setIn([group, "data", 'time'], new Date().getTime())
        .setIn([group, "data", 'loadedTime'], new Date().getTime());
        storaga.setStorageValue("dc-" + group, newState.getIn([group, 'data']));
        return newState;
      }
    case MODEL_RESTORED:
      {
        const {
          data,
          group
        } = action;
        const newState = state.setIn([group, 'status'], 'done').setIn([group, 'data'], data).setIn([group, "data", 'time'], new Date().getTime()).setIn([group, "data", 'restoredTime'], new Date().getTime());
        storaga.setStorageValue("dc-" + group, newState.getIn([group, 'data']));
        return newState;
      }
    case MODEL_UPDATED:
      {
        const {
          data,
          group
        } = action;
        debugger;
        const newState = state.setIn([group, 'status'], 'done').setIn([group, 'data'], data).setIn([group, "data", 'time'], new Date().getTime()); //updated time

        debugger;
        storaga.setStorageValue("dc-" + group, newState.getIn([group, 'data']));
        return newState;
      }
    case METADATA_LOAD_FAILURE:
      {
        const {
          data,
          name
        } = action;
        return state.setIn(['metadata', 'status'], 'failed').setIn(['metadata', 'data'], null);
      }
    default:
      return state;
  }
});