import jsonDataProvider from './embedded/json-data-provider';
import dimensionSelector from './embedded/dimension-selector';
import analysisWheel from './embedded/analysis-wheel';
import scoring from './embedded/scoring';
import evaluationResults from './embedded/evaluation-results';
import jsonInputText from './embedded/json-input-text';
import development from './embedded/development';
import Reducers from './embedded/reducers/index';
const components = {
  Reducers,
  dimensionSelector,
  scoring,
  jsonDataProvider,
  development,
  analysisWheel,
  evaluationResults,
  jsonInputText
};
const getComponentByNameIgnoreCase = name => {
  const k = Object.keys(components).filter(value => value.toLowerCase() == name.toLowerCase());
  return components[k];
};
export { getComponentByNameIgnoreCase, Reducers };